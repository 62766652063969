.about-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    transition: opacity 1s ease-out;
    background-image: linear-gradient(180deg, rgba(250, 178, 224, 0) 0%, rgba(250, 178, 224, 0.045) 26.56%, rgba(250, 178, 224, 0.145) 52.08%, rgba(250, 178, 224, 0.5) 100%);

}

.about-container-appear {
    opacity: 0;
}

.about-container-appear-done {
    opacity: 1;
}

.about-banner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-left: 20%;
    padding-right: 20%;
    margin-bottom: 2rem;
}

#about-name {
    width: 100%;
    overflow-wrap: break-word;
    /* line-height: 400%; */
}

#about-name img {
    max-width: 100%;
}

#about-role {
    width: 100%;
}

#about-role img {
    max-width: 100%;
}

#about-firstname {
    font-family: "BenchNineRegular";
    font-weight: 700;
    font-style: normal;
    padding: 0px 0px 0px 0px;
    text-transform: uppercase;
    /* font-size: 160px; */
    font-size: 5.5rem;
    line-height: 0;
    letter-spacing: 4.7rem;
    /* margin-right: -4.7rem; */
    text-align: centergit;
    color: #FAB2E0;
}

#about-lastname {
    font-family: "BenchNineRegular";
    font-weight: 300;
    font-style: normal;
    text-transform: uppercase;
    /* font-size: 90px; */
    font-size: 2.5rem;
    text-align: center;
    letter-spacing: 4.5rem;
    /* margin-right: -4.5rem; */
    color: #F8F8F8;
}

#about-picture {
    width: 100%;
    margin-bottom: 0.5rem;

}

#about-picture img {
    max-width: 100%;
}

#about-description {
    width: 100%;
    font-family: "BellotaRegular";
    text-align: center;
    font-weight: 300;
    /* font-size: 1.5rem; */
    font-size: 1em;
    font-style: normal;
    color: #F8F8F8;
    padding-left: 10%;
    padding-right: 10%;
    margin-top: 2.5rem;
    margin-bottom: 4rem;

}

#about-email {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-items: center;

    /* background: #FAB2E0;
    border-radius: 60px; */
    padding-left: 20%;
    padding-right: 20%;

    margin-bottom: 1rem;
}

#about-email img {
    max-width: 100%;
}

/* #email-btn {
    background: #FAB2E0;
    border-radius: 4rem;
    width: 100%;
    height: 100px;
    font-family: 'BellotaRegular';
    font-style: normal;
    font-weight: 700;
    font-size: 1.5rem;
    padding: 5px 40px 5px 40px;
    color: #F8F8F8;
    text-align: center;
} */

.caption {
    font-family: 'BellotaRegular';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    color: #F8F8F8;
}

.ellipse {
    width: 10px;
    height: 10px;
    background: #FAB2E0;
    transform: matrix(-1, 0, 0, 1, 0, 0);
}

.about-social {
    display: flex;
    width: 100%;
    font-size: 2rem;
    flex-direction: row;
    justify-content: center;
    text-decoration: none;
    align-items: center;
}

.about-icon {
    text-decoration: none;
    color: #f8f8f8;
    margin-left: 1%;
    margin-right: 1%;
    margin-bottom: 5%;
    transition: color 1s ease;
}

.about-icon:hover {
    color: #FAB2E0
}

.icon {
    opacity: 1.0;
    height: 80px;
    width: 80px;
    background-repeat: no-repeat;
    transition: opacity 0.5s ease-out;
}

.about-content {
    padding-left: 10%;
    padding-right: 10%;
}


.about-section {
    color: #f8f8f8;
    margin-bottom: 2rem;
}

.about-section-heading {
    font-size: 1.2rem;
    font-family: 'BellotaBold';
    /* margin-bottom: 2rem; */
}

.about-section-subheading {
    font-size: 1rem;
    font-family: 'BellotaBold';
    /* margin-bottom: 2rem; */
}

.about-section-content {
    font-size: 0.8rem;
    font-family: 'BellotaRegular';
}