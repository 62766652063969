.portfolio-container {
    display: flex;
    height: 100%;
    width: 100%;
    padding-top: 5%;
    padding-left: 5%;
    padding-right: 5%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: opacity 1s ease-out;
    color: #f8f8f8;
}

.tiled-container {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    flex-wrap: wrap;
    /* justify-content: stretch; */
}

.portfolio-appear {
    opacity: 0;
}

.portfolio-appear-done {
    opacity: 1;
}

.portfolio-group {
    width: 100%;
    margin-bottom: 2rem;
}

.portfolio-group-heading {
    font-size: 2rem;
    font-family: 'BenchNineBold';
    /* margin-bottom: 2rem; */
}