.thumbnail {
    position: relative;
    text-align: center;
    opacity: 1.0;
    /* background-color: yellow; */
    transition: opacity 0.2s ease-out;
    transition: scale 0.2s ease-out;
    margin: 1% 1% 1% 1%;
    border-radius: 1%;
    flex-shrink: 0;
    width: 300px;
    height: 300px;
    overflow: hidden;
    border-radius: 10%;
    /* align-self: stretch; */
    background-color: white;
}

@media (width < 600px) {
    .thumbnail {
        margin-bottom: 5%;
    }
}

.thumbnail img {
    width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    object-fit: cover;
}

.thumbnail:hover {
    background-color: black;
}

.thumbnail .thumbnail-text {
    opacity: 0;
    width: 100%;
    /* width: 0; */
    position: absolute;
    color: #f8f8f8;
    font-family: "BenchNineBold";
    letter-spacing: 0.2rem;
    text-transform: uppercase;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1rem;
    transition: opacity 0.3s ease;
}

.thumbnail:hover .thumbnail-text {

    opacity: 1;
}

.thumbnail:hover img {
    opacity: 0.2;
    /* transform: scale(1.1); */
}