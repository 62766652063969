.project-title {
    color: white;
    font-family: "BenchNineBold";
    text-transform: capitalize;
    font-size: 2rem;
    margin-bottom: 2rem;
}

.project-description {
    color: #f8f8f8;
    font-size: 1rem;
    font-family: 'BenchNineRegular';
    margin-bottom: 2rem;
}

.project-section {
    color: #f8f8f8;
    margin-bottom: 2rem;
    margin-top: 2rem;
}

.section-heading {
    font-size: 2rem;
    font-family: 'BenchNineBold';
    /* margin-bottom: 2rem; */
}

.section-subheading {
    font-size: 1.5rem;
    font-family: 'BenchNineBold';
    /* margin-bottom: 2rem; */
}

.section-content {
    font-size: 1rem;
    font-family: 'BenchNineRegular';
}

.credit-name {
    font-family: "BenchNineBold";
}

.credit-role {
    font-family: "BenchNineRegular";
}

/* This is hacky taken from https://stackoverflow.com/a/41660020 */
.project-video {
    width: 100%;
    height: 0;
    padding-bottom: 56%;
    /* Change this till it fits the dimensions of your video */
    position: relative;
    margin-bottom: 3rem;
}

.project-video iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    display: block;
    top: 0;
    left: 0;
}