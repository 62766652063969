.mythical-grid {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.mythical-character {
    width: 80%;
    margin-bottom: 0.5rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    min-width: 300px;
    max-width: 800px;
    font-size: 1rem;
    align-self: stretch;
    overflow: hidden;
    border-radius: 10%;
    background-color: white;

}

.mythical-character img {
    width: 100%;
    height: 100%;
    object-fit: contain;

}