.navigation-header {
    display: flex;
    display: block;
    padding-top: 1%;
    padding-left: 1%;
    padding-right: 1%;
    height: 40%;

}

.navigation-container {
    display: flex;
    padding-left: 5%;
    /* padding-right: 1%; */
    width: 100%;
    /* height: 30%; */
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-right: 10%;

}

@media (width < 600px) {
    .navigation-container {
        flex-direction: column;
        margin-bottom: 5%;
    }
}

.logo {
    width: 15%;
    min-width: 150px;
    max-width: 200px;
}

.logo img {
    padding-left: auto;
    padding-right: auto;
    max-width: 100%;
}

.links {
    width: 100%;
    min-width: 200px;
}

.nav-item-group {
    display: flex;
    width: 100%;
    /* padding-right: 10%; */
    flex-direction: row;
    justify-content: right;
    align-items: baseline;
}

.nav-item {
    width: 150px;
    margin-left: 1%;
    margin-right: 1%;
}


/* .nav-item-active {
    opacity: 1;
    position: absolute;
    top: 0px;
    left: 0px;
    transition: opacity 1.5s ease;
}

.nav-item-inactive {
    opacity: 0;
    width: 0;
    position: absolute;
    top: 0px;
    left: 0;
    transition: opacity 1.5s ease-out;

} */

/* @media (width < 600px) {
    .nav-item-group {
        display: none;
    }

    .nav-item-kebab {
        display: block;
        width: 10%;

    }
} */


/* .nav-item:hover {
    transform: translate(-0.25em, -0.25em);
} */

/* .nav-item:after {
    content: '';
    display: block;
    margin: auto;
    height: 3px;
    width: 0px;
    background: transparent;
    transition: width .5s ease, background-color .5s ease;
} */

/* .nav-item:hover:after {
    width: 100%;
    background: yellow;
} */

.nav-link {
    text-decoration: none;
    color: white;
}

.nav-link img {
    max-width: 100%;
}

/* #portfolio-btn {
    font-family: "BellotaBOld";
    font-size: 1.2rem;
    color: #F8F8F8;
    margin-left: 2%;
    margin-right: 2%;
}

#portfolio-btn img {
    max-width: 100%;
}

#about-btn {
    font-family: "BellotaBOld";
    font-size: 1.2rem;
    margin-left: 2%;
    margin-right: 2%;
}



#about-btn img {
    max-width: 100%;
} */

@media (width < 600px) {
    /* .navigation-header {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    } */

    .navigation-container {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-right: 0;
        padding-left: 0;
    }

    .nav-item-group {
        flex-direction: column;
        width: 100%;
        justify-content: center;
        align-items: center
    }

    .nav-item {
        margin-top: 2%;
        margin-bottom: 2%;
    }
}