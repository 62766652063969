.splash-container {
    position: relative;
    width: 100vw;
    height: 100vh;
    background-color: black;
}

.splash {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 200px;
    min-width: 100px;
}

.splash img {
    max-width: 100%;
}