.vector-grid {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.vector-character {
    margin-bottom: 1rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    width: 300px;
    height: 300px;
    font-size: 1rem;
    align-self: stretch;
    overflow: hidden;
    border-radius: 10%;
    background-color: white;
}

.vector-character img {
    width: 100%;
    height: 100%;
    object-fit: cover;

}