.icon {
    height: 20%;
    width: 20%;
    /* margin-top: 2%; */
    /* margin-bottom: 1%; */
    margin-right: 1%;
    margin-left: 1%;
    background-repeat: no-repeat;
    transition: opacity 0.5s ease-out;
}

.icon-opaque {
    opacity: 1.0;
    /* background: #998091; */
}

.icon-transparent {
    opacity: 0.2;
}