.characters-grid {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.character {
    width: 28%;
    margin-bottom: 1rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    max-width: 200px;
    font-size: 1rem;
}

.character img {
    width: 100%;
    border-radius: 10%;
}