.project-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding-left: 10%;
    padding-right: 10%;
    letter-spacing: 0.1rem;

    color: #f8f8f8;
    font-size: 1rem;
    font-family: 'BenchNineRegular'
}

.project-container-appear {
    opacity: 0;
}

.project-container-appear-done {
    opacity: 1;
}